@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
body,
h1, h2, h3, h4, h5, h6,
p, pre, ol, ul, blockquote {
	margin: unset;
}
ol, ul {
	padding: unset;
}
input, select,
::-webkit-file-upload-button {
	border: unset;
	border-radius: unset;
}
h1, h2, h3, h4, h5, h6,
pre,
strong, em, dfn,
input, select, textarea,
::-webkit-file-upload-button {
	font: unset;
}
:any-link {
	text-decoration: unset;
}

@mixin fade-in($direction) {
	@keyframes fade-in-#{$direction} {
		from {
			#{$direction}: -24px;
			opacity: 0;
		}
		to {
			#{$direction}: 0px;
			opacity: 1;
		}
	}
	@keyframes fade-in-#{$direction}-relative {
		from {
			position: relative;
			#{$direction}: -24px;
			opacity: 0;
		}
		to {
			position: relative;
			#{$direction}: 0px;
			opacity: 1;
		}
	}
}
@include fade-in(top);
@include fade-in(right);
@include fade-in(bottom);
@include fade-in(left);
html {
	font: 100%/1.50 "Lato", "Arial", sans-serif;
	color: #474130;
	background: #E8E6DC;
	overflow-x: hidden;
	@media (min-width: 1200px) {
		font-size: 1.25em;
	}
	&.bg--locked {
		overflow: hidden;
	}
}
.bg-site {
	.bg--button {
		display: inline-block;
		padding: 0.25em 1em;
		font-size: 0.60em;
		font-weight: 700;
		text-transform: uppercase;
		color: white;
		background: #61550B;
	}
	.bg--content_area {
		max-width: 1200px;
		padding: 1.50rem 1rem;
		margin: {
			right: auto;
			left: auto;
		}
		@media (min-width: 768px) {
			padding: 3rem 2rem;
		}
	}
	.bg--animate_in {
		animation-play-state: paused !important;
		animation-fill-mode: both !important;
		&.bg--running {
			animation-play-state: running !important;
		}
	}
	[data-tab] {
		display: none;
		&[data-tab="active"] {
			display: block;
		}
	}
	[data-filter-visible] {
		display: none;
		&[data-filter-visible="true"] {
			display: list-item;
		}
	}
	.bg-site-header {
		position: sticky;
		top: 0;
		z-index: 500;
		color: white;
		background-color: #878067;
		.bg--content_area {
			display: flex;
			padding: 1rem;
			justify-content: space-between;
			align-items: center;
			@media (min-width: 768px) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
			}
		}
		h1 {
			font-size: 1.25em;
			img {
				display: block;
				height: 1em;
			}
		}
		ul {
			display: block;
			box-sizing: border-box;
			width: 100%;
			padding: 1.5rem 1rem;
			position: fixed;
			top: 0;
			right: calc(-100% - 24px);
			bottom: 0;
			font-size: 2em;
			line-height: 1.75em;
			background-color: #878067;
			list-style: none;
			box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.75);
			transition: {
				property: right;
				duration: 0.25s;
			}
			@media (min-width: 768px) {
				display: grid;
				padding: unset;
				grid-auto-flow: column;
				list-style: none;
				justify-content: end;
				column-gap: 1em;
				position: static;
				font-size: 1em;
				line-height: unset;
				box-shadow: unset;
			}
			&.bg--toggled {
				right: 0;
			}
		}
		li {
			font-size: 0.80em;
			text-transform: uppercase;
			&.bg--outline {
				padding: {
					right: 0.5em;
					left: 0.5em;
				}
				outline: thin solid white;
			}
		}
		:any-link {
			color: currentColor;
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
		#bg-site-header-toggle {
			padding: 0.5em 0.75em;
			border: none;
			border-radius: 2px;
			position: relative;
			z-index: 400;
			line-height: 1;
			font-weight: bold;
			color: #878067;
			background-color: white;
			text-transform: uppercase;
			@media (min-width: 768px) {
				display: none;
			}
		}
	}
	.bg-site-footer {
		text-align: center;
		color: white;
		background-color: #5D5849;
		@media (min-width: 768px) {
			text-align: unset;
		}
		.bg--content_area {
			@media (min-width: 768px) {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}
		}
		ul {
			list-style: none;
		}
		:any-link {
			color: currentColor;
			text-decoration: underline;
			&:focus,
			&:hover {
				text-decoration: double underline;
			}
		}
		.bg--colophon {
			margin-top: 1.5em;
			@media (min-width: 768px) {
				margin-top: unset;
				text-align: end;
			}
			img {
				height: 3em;
				margin-bottom: 0.5em;
				margin-left: auto;
				opacity: 0.35;
			}
		}
	}
}
.bg-hero {
	--hero-height: 40vh;
	--hero-max-height: 16em;
	position: relative;
	background: white;
	@media (min-width: 768px) {
		--hero-height: 50vh;
		--hero-max-height: 36em;
	}
	@media (min-width: 1200px) {
		--hero-height: 100vh;
		--hero-max-height: 36em;
	}
	.bg--button {
		background-color: var(--accent-color);
	}
	.bg--scroll_hint {
		@keyframes arrow {
			from {
				color: rgba(255, 255, 255, 0.15);
			}
			to {
				color: rgba(255, 255, 255, 0.95);
			}
		}
		display: flex;
		width: 100%;
		padding: 1em;
		border: unset;
		position: absolute;
		bottom: 0;
		justify-content: center;
		font-size: 1.5em;
		color: rgba(255, 255, 255, 0.75);
		background-color: unset;
		animation-name: arrow;
		animation-delay: 1.5s;
		animation-duration: 1s;
		animation-fill-mode: both;
		svg {
			display: block;
			height: 1em;
			fill: currentColor;
		}
		&:focus,
		&:hover {
			color: white;
			background-color: rgba(255, 255, 255, 0.25);
		}
	}
	&::after {
		content: "";
		display: table;
		clear: both;
		@media (min-width: 768px) {
			content: none;
		}
	}
	.bg-hero-background {
		display: block;
		width: 100%;
		height: var(--hero-height);
		max-height: var(--hero-max-height);
		object-fit: cover;
		@media (min-width: 1200px) {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			object-fit: cover;
		}
	}
	.bg-hero-foreground {
		img {
			display: block;
			width: auto;
			height: var(--hero-height);
			max-height: var(--hero-max-height);
			position: absolute;
			top: 0;
			@media (min-width: 1024px) {
				right: 60%;
			}
			@media (min-width: 1200px) {
				right: 55%;
			}
		}
	}
	.bg-hero-main {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		@media (min-width: 768px) {
			width: 40%;
			padding-right: 5%;
			position: absolute;
			bottom: 2em;
			right: 0;
			font-size: 1.25em;
			color: white;
		}
		@media (min-width: 1024px) {
			width: 50%;
			bottom: 0;
		}
		@media (min-width: 1200px) {
			width: 19em;
			padding: {
				right: 4em;
				left: unset;
			}
			bottom: 3em;
			margin-right: calc((100% - 1200px - 4rem) / 2);
			font-size: 1.45em;
			animation: 1s fade-in-right 0.50s;
		}
		p {
			margin: {
				top: 1.50em;
				bottom: 1.50em;
			}
		}
		.bg-hero-main-logo {
			display: block;
			height: 4.25em;
			width: auto;
			position: absolute;
			margin-top: calc(-5em - 3rem);
			right: 1rem;
			opacity: 0.21;
			filter: drop-shadow(13px 13px 24px black);
			@media (min-width: 768px) {
				position: unset;
				margin: {
					top: unset;
					bottom: 2em;
				}
			}
		}
		.bg-hero-main-text {
			@media (min-width: 768px) {
				text-shadow: 0 0 24px black, 0 0 48px black;
			}
		}
	}
	.bg--content_area {
		display: contents;
		@media (min-width: 1200px) {
			display: block;
			height: calc(var(--hero-height) - 6rem);
			max-height: calc(var(--hero-max-height) - 6rem);
		}
	}
	&.bg--text_left {
		.bg-hero-background {
			object-position: center top;
		}
		.bg-hero-foreground {
			img {
				@media (min-width: 1200px) {
					left: 50%;
				}
			}
		}
		.bg-hero-main {
			@media (min-width: 1200px) {
				padding: {
					right: unset;
					left: 4em;
				}
				margin-left: calc((100% - 1200px - 4rem) / 2);
				font-size: 1.25em;
				animation: 1s fade-in-left 0.50s;
			}
		}
	}
}
.bg-defbox {
	line-height: 1.625;
	font-size: 0.75em;
	background-color: #E5E8DC;
	dfn {
		display: block;
		margin-bottom: 0.25em;
		font-size: 1.5em;
		font-weight: 600;
		text-transform: uppercase;
	}
	img {
		height: 12em;
	}
	p {
		text-align: end;
	}
	.bg--content_area {
		@media (min-width: 768px) {
			display: grid;
			padding: {
				top: 1em;
				bottom: 1em;
			}
			grid-template-columns: 32em 28em;
			gap: 4em;
			justify-content: end;
			align-items: center;
		}
	}
}
.bg-post {
	.bg--lead {
		font-weight: 700;
		font-size: 1.25em;
	}
	h2, h3, h4, h5,
	p, pre, ol, ul {
		margin-bottom: 1.5rem;
		&:last-child {
			margin-bottom: unset;
		}
	}
	h2, h3, h4, h5 {
		line-height: 1.25;
	}
	h2 {
		font-size: 1.75em;
		margin-bottom: 0.75em;
	}
	h3 {
		font-size: 1.5em;
	}
	h4 {
		font-size: 1.5em;
	}
	strong {
		font-weight: 700;
	}
	.bg-post-main {
		background-color: white;
	}
}
.bg-text {
	font-weight: 300;
	font-size: 1.15em;
	h2 {
		font-weight: 400;
	}
	h3 {
		font-weight: 400;
	}
	h4 {
		font-weight: 600;
	}
	h5 {
		font-weight: 600;
		color: #52742C;
		text-transform: uppercase;
	}
	&.bg--intro {
		font-size: 1.35em;
	}
	:any-link {
		font-weight: 400;
		color: #52742C;
		text-decoration: underline;
		&:focus,
		&:hover {
			text-decoration: double underline;
		}
	}
}
.bg-callout {
	font-weight: 300;
	font-size: 1.15em;
	color: var(--foreground-color);
	background: var(--background-color);
	.bg-callout-media {
		width: 100%;
		@media (min-width: 768px) {
			display: flex;
			justify-content: flex-end;
			margin-right: 2rem;
			animation: 1s fade-in-left-relative 0.25s both;
		}
		@media (min-width: 1200px) {
			padding-left: 2em;
		}
		img {
			max-width: 100%;
		}
	}
	.bg-callout-text {
		border-left: thin solid currentColor;
		padding-left: 1em;
		@media (min-width: 1200px) {
			padding-right: 2em;
		}
		:any-link {
			font-weight: 600;
			color: unset;
			text-decoration: underline;
			&:focus,
			&:hover {
				text-decoration: double underline;
			}
		}
		> * {
			&:last-child {
				@media (min-width: 768px) {
					margin-bottom: unset;
				}
			}
		}
		&.bg--quote {
			border-left: unset;
			padding: 2em 0;
			margin: {
				right: auto;
				left: auto;
			}
			text-align: center;
			font-size: 1.25em;
			max-width: 36em;
			.bg--cite {
				margin-top: 2em;
				font-size: 0.75em;
			}
		}
		&.bg--aside {
			border-left: unset;
			padding: 2em 0;
			margin: {
				right: auto;
				left: auto;
			}
			text-align: center;
			font-size: 0.85em;
			font-weight: 400;
			line-height: 2;
			h3 {
				font-size: 2.25em;
			}
		}
	}
	.bg--content_area {
		@media (min-width: 768px) {
			display: flex;
			align-items: center;
			padding: {
				top: 3.25rem;
				bottom: 3.25rem;
			}
		}
	}
}
.bg-spiral {
	.bg-spiral-pair {
		display: contents;
		.bg-spiral-pair-media {
			@media (min-width: 768px) {
				justify-self: end;
				animation: 1s fade-in-left-relative 0.25s;
			}
			img {
				display: block;
				width: 100%;
				@media (min-width: 768px) {
					width: unset;
					height: 14em;
				}
			}
		}
		.bg-spiral-pair-text {
			@media (min-width: 768px) {
				max-width: 22em;
			}
			h3 {
				font-weight: 700;
				font-size: 1.50em;
				margin: {
					top: 1rem;
					bottom: 1rem;
				}
				@media (min-width: 768px) {
					margin-top: unset;
				}
			}
			:any-link {
				color: #61550B;
				font-weight: 700;
				text-decoration: underline;
			}
			> * {
				&:last-child {
					@media (min-width: 768px) {
						margin-bottom: unset;
					}
				}
			}
		}
		&:nth-child(even) {
			.bg-spiral-pair-text {
				@media (min-width: 768px) {
					text-align: right;
					justify-self: end;
				}
			}
			.bg-spiral-pair-media {
				@media (min-width: 768px) {
					grid-column: 2;
					justify-self: start;
					animation-name: fade-in-right-relative;
				}
			}
		}
	}
	.bg--content_area {
		@media (min-width: 768px) {
			display: grid;
			grid-auto-flow: row dense;
			gap: 3em 4rem;
			grid-template-columns: repeat(2, 1fr);
			align-items: center;
		}
	}
}
.bg-overflow {
	position: relative;
	overflow: hidden;
	height: var(--overflow-height);
	.bg-overflow-read_more {
		display: flex;
		height: 8em;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		color: #52742C;
		text-transform: uppercase;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 45%);
		cursor: pointer;
	}
}
.bg-split {
	.bg--content_area {
		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: min-content 24em;
			column-gap: 2em;
			justify-content: center;
			align-items: center;
		}
	}
	.bg-split-text {
		text-align: center;
	}
}
.bg-triptych {
	font-size: calc(1em / 1.15);
	font-weight: 400;
	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2em;
	}
	hgroup {
		margin-bottom: 1.5em;
		h4 {
			margin-bottom: unset;
			font-size: 1.25em;
		}
		h5 {
			color: unset;
			text-transform: unset;
		}
	}
	h4 {
		font-size: 1.5em;
	}
	img {
		display: block;
		height: 10em;
		margin: {
			right: auto;
			bottom: 2em;
			left: auto;
		}
	}
	p {
		color: #666;
	}
	.bg-triptych-panel {
		img {
			animation: 1s fade-in-bottom-relative;
		}
	}
}
.bg-dialog {
	display: none;
	width: unset;
	height: unset;
	border: unset;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 900;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.75);
	.bg-dialog-form {
		box-sizing: border-box;
		max-width: 100%;
		max-height: 100vh;
		padding: 1.5em 2em;
		color: #474130;
		background-color: #E8E6DC;
		overflow: auto;
		h3 {
			font-size: 1.5em;
			font-weight: bold;
		}
		label,
		input,
		textarea {
			display: block;
		}
		label {
			margin: {
				top: 0.75em;
				bottom: 0.25em;
			}
			font-size: 0.75em;
		}
		p {
			max-width: 32em;
		}
		input,
		textarea {
			box-sizing: border-box;
			width: 100%;
			border: thin solid currentColor;
		}
		input[type="submit"] {
			display: inline-block;
			width: unset;
			min-width: unset;
			padding: 0.25em 1em;
			border: unset;
			margin-right: 1em;
			color: white;
			background-color: #52742C;
		}
		.bg--tray {
			display: flex;
			margin-top: 1.5em;
			justify-content: flex-start;
			align-items: baseline;
		}
		.bg-dialog-form-top {
			display: flex;
			justify-content: space-between;
			align-items: start;
			button {
				padding: 0.25em 1em;
				border: thin solid currentColor;
				border-radius: unset;
				font: unset;
				font-size: 0.75em;
				color: #474130;
				background-color: unset;
			}
		}
	}
	&[open] {
		display: flex;
	}
}
